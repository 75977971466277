<template>
  <div>
    <LoadingState v-if="isLoading" />
    <main-card v-else>
      <span slot="title1">ATTENDANCE REPORT</span>
      <span slot="title2">{{ header.kelas }}</span>
      <span slot="sub-title1">{{ header.totalMurid }} Students</span>
      <span slot="sub-title2"
        >{{ header.tahunAjar }} ({{ header.semester }})</span
      >
      <div slot="content" v-if="header.idKelas">
        <table-daily-attendance-report
          :dataClass="header"
        />
      </div>
    </main-card>
  </div>
</template>

<script>
const mainCard = () => import('@/components/app/shared/mainCard')
const LoadingState = () => import('@/components/app/LoadingState')
const TableDailyAttendanceReport = () => import('@/components/app/Homeroom/TableDailyAttendanceReport')

export default {
  components: {
    mainCard,
    LoadingState,
    TableDailyAttendanceReport,
  },
  data() {
    return {
      header: {},
    }
  },
  methods: {
    async fetchHeader() {
      try {
        const idKelas = this.$route?.params?.id_kelas
        const res = await this.$store.dispatch('homeroom/FETCH_CURRICULUM_HEADER_PERSONAL_DATA', { idKelas })
        this.header = {
          ...res,
          idKelas,
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  mounted() {
    this.fetchHeader()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.scrolling-card {
  height: 600px;
  overflow-y: auto;
}
</style>
