<template>
  <div>
    <LoadingState v-if="isLoading" />
    <main-card v-else>
        <span slot="title1">Graduation Letter</span>
        <span slot="title2">{{header.kelas}}</span>
        <span slot="sub-title1">{{header.totalMurid}} Students</span>
        <span slot="sub-title2">{{header.tahunAjar}} ({{header.semester}})</span>
        <div slot="content">
          <a-row :gutter="16">
            <a-col :lg="8" :md="24" v-sticky="{ zIndex: 99, stickyTop: 10, disabled: isDisabledSticky}">
              <div class="border rounded">
                <div class="card-header">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      w-100
                    "
                  >
                    <div>
                      <h2 class="font-size-18 text-dark">List of Students</h2>
                    </div>
                  </div>
                </div>
                <div class="card-body scrolling-card">
                  <template v-if="loadingListPersonalData">
                    <a-skeleton v-for="item in 5" :key="item" active :paragraph="{ rows: 2 }" />
                  </template>
                  <Menu v-else :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <a class="text-primary" @click.prevent="backToMenu">Back to Menu</a>
                    </div>
                    <div>
                      <kbd class="bg-primary">↑</kbd>
                      <kbd class="bg-primary mx-2">↓</kbd>
                      <span>To Navigate</span>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :lg="16" :md="24">
              <div class="border rounded">
                <div class="card-header">
                  <div
                    class="
                      d-flex
                      flex-column flex-md-row
                      align-items-start align-items-md-center
                      w-100
                    "
                  >
                    <div>
                      <h2 class="font-size-18 text-dark">Preview</h2>
                    </div>
                    <div class="ml-0 ml-md-auto">
                      <div v-if="dataGraduationLetter.uuid" class="text-center">
                        <a-button :loading="isExported" @click.prevent="exportGraduationLetter('export')" type="primary" icon="download">Download Graduation Letter</a-button>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="loadingDetailPersonalData || !menus.length">
                    <a-skeleton v-for="item in 5" :key="item" active :paragraph="{ rows: 2 }" />
                </template>
                <template v-else>
                  <template v-if="dataGraduationLetter.uuid">
                    <graduation-letter :configSchool="config.school" :dataGraduationLetter="dataGraduationLetter || {}" />
                    <!-- <template v-if="dataGraduationLetter.version === null">
                      <report-mid-view v-if="type === 'mid'" :configSchool="config.school" :generalReport="dataGraduationLetter" />
                      <report-final-view v-else-if="type === 'final'" :configSchool="config.school" :generalReport="dataGraduationLetter" page />
                    </template>
                    <template v-if="dataGraduationLetter.version === 1">
                      <report-mid-view-v2 v-if="type === 'mid'" :configSchool="config.school" :dataGraduationLetter="dataGraduationLetter" />
                      <report-final-view-v2 v-else-if="type === 'final'" :configSchool="config.school" :dataGraduationLetter="dataGraduationLetter" page />
                    </template> -->
                  </template>
                  <EmptyState
                    class="my-5"
                    v-else
                    heading="No Data"
                    description="This student still has no Graduation Letter."
                  />
                </template>
              </div>
            </a-col>
          </a-row>
        </div>
    </main-card>
    <template v-if="dataGraduationLetter.uuid">
      <div class="d-none">
        <div id="reportHtml" class="w-100">
          <graduation-letter
            :format="
              isExported
                ? { width: '650px', height: '1037px' }
                : null
            "
            :configSchool="config.school"
            :dataGraduationLetter="dataGraduationLetter"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import config from '@/config'
import VueSticky from 'vue-sticky'
import JsPdf from 'jspdf'
// import { dummyMurid } from '@/helpers/graduationLetterDummy.js'

const mainCard = () => import('@/components/app/shared/mainCard')
const LoadingState = () => import('@/components/app/LoadingState')
const Menu = () => import('@/components/app/MenuV2')
const GraduationLetterComponent = () => import('@/components/app/GraduationLetter')
const EmptyState = () => import('@/components/app/EmptyState')
// const ReportMidView = () => import('@/components/app/Report/Mid')
// const ReportFinalView = () => import('@/components/app/Report/Final')
// const ReportMidViewV2 = () => import('@/components/app/Report/Mid/v2')
// const ReportFinalViewV2 = () => import('@/components/app/Report/Final/v2')

export default {
  directives: {
    sticky: VueSticky,
  },
  components: {
    mainCard,
    LoadingState,
    Menu,
    // ReportMidView,
    // ReportFinalView,
    // ReportMidViewV2,
    // ReportFinalViewV2,
    EmptyState,
    GraduationLetter: GraduationLetterComponent,
  },
  data() {
    return {
      config,
      menuActive: 1,
      header: {},
      menus: [],
      loadingListPersonalData: false,
      loadingDetailPersonalData: false,
      idKelas: this.$route.params.id_kelas,
      isMetadata: false,
      arrayGraduationLetter: [],
      isExported: false,
    }
  },
  methods: {
    backToMenu() {
      this.$router.push({ name: 'Curriculum Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Currriculum Teacher'],
      })
    },
    menuChange(payload) {
      this.menuActive = payload
    },
    async fetchHeader(query = '') {
      try {
        const res = await this.$store.dispatch('homeroom/FETCH_CURRICULUM_HEADER_PERSONAL_DATA', { idKelas: this.idKelas })
        this.header = res
        this.menus = res.murid.map((el, i) => {
          return {
            key: i + 1,
            menu: `${el.murid.nama}`,
            ...el,
          }
        })
        this.fetchPersonalData()
      } catch (err) {
        console.log(err)
      }
    },
    async fetchPersonalData() {
      try {
        this.loadingListPersonalData = true
        this.loadingDetailPersonalData = true
        const res = await this.$store.dispatch('curriculum/FETCH_GRADUATION_LETTER_BY_CLASS', { idKelas: this.idKelas })
        this.loadingListPersonalData = false
        this.loadingDetailPersonalData = false
        this.arrayGraduationLetter = res.data
        this.isMetadata = res.isMetadata
        this.menuActive = 1
        // let no = 1
        // this.menus = res.map(el => {
        //   return {
        //     key: no++,
        //     menu: `${el.nama}`,
        //     ...el,
        //   }
        // })
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        this.loadingListPersonalData = false
        this.loadingDetailPersonalData = false
        return new Promise((resolve, reject) => reject(err))
      }
    },
    exportGraduationLetter(type = 'export') {
      if (type === 'export') this.loadingExport = true
      else if (type === ' print') this.loadingPrint = true
      // this.$refs.html2Pdf.generatePdf()
      const data = document.getElementById('reportHtml')
      this.isExported = true
      const doc = new JsPdf({
        // orientation: 'p',
        unit: 'px',
        format: 'a4',
        // putOnlyUsedFonts: true,
      })
      const { data_murid: murid, data_kop_surat: kop } = this.dataGraduationLetter
      doc.html(data, {
        callback: (doc) => {
          this.isExported = false
          if (type === 'print' || type === 'export') {
            if (type === 'export') doc.save(`SKL ${murid.nama.toUpperCase()} ${kop.nama_sekolah} ${kop.tahun_ajaran}` + '.pdf')
            else {
              // doc.autoPrint()
              // doc.output('dataurlnewwindow')
              window.open(URL.createObjectURL(doc.output('blob')))
            }
            this.loadingExport = false
            this.loadingPrint = false
          }
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
          scale: 0.6,
        },
        margin: [10, 10, 10, 10],
      })
      // doc.text('Hello world!', 10, 10)
      // doc.save('a4.pdf')
    },
  },
  created() {
    if (this.$route.query.type === 'mid' || this.$route.query.type === 'final') {
      this.type = this.$route.query.type
    }
    this.fetchHeader()

    window.addEventListener('keyup', (event) => {
      event.preventDefault()
      if (this.menus.length) {
        if (event.keyCode === 40 && this.menuActive !== this.menus.length) {
          this.menuActive = this.menuActive + 1
        } else if (event.keyCode === 38 && this.menuActive !== 1) {
          this.menuActive = this.menuActive - 1
        }
      }
    })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    dataGraduationLetter() {
      const dataMurid = this.menus[this.menuActive - 1]
      if (dataMurid) {
        const dataGrad = this.arrayGraduationLetter.find(grad => grad.data_murid.id_murid === dataMurid.id_murid)
        return {
          ...dataGrad,
          key: this.menuActive,
        }
      }
      return {}
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    isPrincipal() {
      return this.$store.state.user.role.includes('kepalaSekolah')
    },
  },
}
</script>

<style lang="scss">
.scrolling-card {
  height: 600px;
  overflow-y: auto;
}
</style>
